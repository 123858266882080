import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
loadFonts();
console.log(window.location.hostname);
async function initApp() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: window.location.hostname === "quizzr.in" || window.location.hostname == "portal.quizzr.in" ? "ap-south-1_LOJASW2jL" : "ap-south-1_1Yv7yZz4c",
        userPoolClientId: window.location.hostname === "quizzr.in" || window.location.hostname == "portal.quizzr.in" ? "1t4cahjk29ks3ddcajk6mul4ks" : "63tbjgbfn782ufpma7oe5fuo42",
        loginWith: {
          email: true,
        },
        signUpVerificationMethod: "code",
      },
    },
    API: {
      GraphQL: {
        endpoint:
          window.location.hostname === "quizzr.in" || window.location.hostname == "portal.quizzr.in"
            ? "https://egkruftd4fbhxc4xiypkbc2puy.appsync-api.ap-south-1.amazonaws.com/graphql"
            : "https://5e7gx6cqjrha3gx2aglinualtq.appsync-api.ap-south-1.amazonaws.com/graphql",
        region: "ap-south-1",
        defaultAuthMode: "userPool",
      },
    },
  });
  Hub.listen("auth", ({ payload }) => {
    switch (payload.event) {
      case "signedIn":
        console.log("user have been signedIn successfully.");
        break;
      case "signedOut":
        console.log("user have been signedOut successfully.");
        break;
      case "tokenRefresh":
        console.log("auth tokens have been refreshed.");
        break;
      case "tokenRefresh_failure":
        console.log("failure while refreshing auth tokens.");
        this.$router.push("/");
        break;
      case "signInWithRedirect":
        console.log("signInWithRedirect API has successfully been resolved.");
        break;
      case "signInWithRedirect_failure":
        console.log("failure while trying to resolve signInWithRedirect API.");
        break;
      case "customOAuthState":
        logger.info("custom state returned from CognitoHosted UI");
        break;
    }
  });
}
initApp().then(() => {
  createApp(App).use(router).use(store).use(vuetify).mount("#app");
});
