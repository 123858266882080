import { generateClient } from "aws-amplify/api";
import { recordUserActivity } from "@/graphql/mutations.js";
const client = generateClient();

export const UpdateUserDetails = {
  data: () => ({}),
  methods: {
    async UpdateUserDetailsMethod(logout_time, location, device_type, browser_name) {
      try {
        console.log("Location", location);
        await client.graphql({
          query: recordUserActivity,
          variables: {
            input: {
              logout_time: logout_time !== undefined ? logout_time : undefined,
              user_location: location !== undefined && location !== null ? location : "User Restricted",
              device_type: device_type !== undefined && device_type !== null ? device_type : undefined,
              browser_name: browser_name !== undefined && browser_name !== null ? browser_name : undefined,
            },
          },
        });
      } catch (error) {
        console.log("Err", error);
        return {
          status: "ERROR",
          message: error,
        };
      }
    },
  },
};
